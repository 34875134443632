import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const UnlockEnterprise = () => {
  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ backgroundColor: "whitesmoke", padding: "3rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-9 text-center mx-auto">
            {/* <h2 className="my-2 font-weight-medium text-left-mobile">
              Unlock Enterprise Solutions
            </h2>
            <p className="text-left-mobile">
              Your team craves efficiency. The lean applications we design
              streamline everything. It's time to smash through bottlenecks and
              discover how productive your organization can be!
            </p> */}
            <h3 className="my-5 text-left-mobile">
              We Proudly Partner with Leading Low-Code/No-Code Platforms
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6 text-center mb-5 mb-md-0">
            <div
              className="img-wrapper"
              style={{
                height: "10rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StaticImage
                placeholder="blurred"
                className="img-fluid rounded"
                src="https://cdn.sanity.io/images/55lcecww/production/5dcc11dfa83272784d3b4a9d61a4a59062d2b40c-1788x292.png?w=600"
                alt="Quickbase Logo"
                layout="constrained"
                style={{ width: "95%" }}
                loading="lazy"
                fetchpriority="low"
              />
            </div>
            <h3>Quickbase</h3>
            <p className="my-4" style={{ minHeight: "0rem" }}>
              As Quickbase Elite Partners, we use low code to help you conquer
              everyday challenges with custom applications that deploy rapidly.
            </p>
            <br />
            <Link
              className="btn btn-primary btn-arrow"
              to="/quick-base-solutions/"
            >
              Learn More
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6 text-center mb-5 mb-md-0">
            <div
              className="img-wrapper"
              style={{
                height: "10rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StaticImage
                placeholder="blurred"
                className="img-fluid rounded"
                src="https://cdn.sanity.io/images/55lcecww/production/549f674c0eeae5959e36248b5337e51c4e72d927-900x534.png?w=600"
                alt="Workato Logo"
                layout="constrained"
                style={{ width: "45%" }}
                loading="lazy"
                fetchpriority="low"
              />
            </div>
            <h3>Workato</h3>
            <p className="my-4" style={{ minHeight: "0rem" }}>
              We integrate and automate workflows with Workato, reducing errors
              and giving you more time to focus on your business.
            </p>
            <br />
            <Link
              className="btn btn-primary btn-arrow"
              to="/workato-solutions/"
            >
              Learn More
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6 text-center mb-5 mb-md-0">
            <div
              className="img-wrapper"
              style={{
                height: "10rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StaticImage
                placeholder="blurred"
                className="img-fluid rounded"
                src="https://cdn.sanity.io/images/55lcecww/production/f5656afd01bfd93222721a81b219743d7a59d393-187x133.png?w=600"
                alt="peakSuite Logo"
                layout="constrained"
                style={{ width: "65%" }}
                loading="lazy"
                fetchpriority="low"
              />
            </div>
            <h3>Pipefy</h3>
            <p className="my-4" style={{ minHeight: "0rem" }}>
              We use Pipefy to integrate business processes and workflows so you
              can scale better and reduce your back-office overhead.
            </p>
            <br />
            <Link className="btn btn-primary btn-arrow" to="/pipefy/">
              Learn More
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 text-center mb-5 mb-md-0">
            <div
              className="img-wrapper"
              style={{
                height: "10rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StaticImage
                placeholder="blurred"
                className="img-fluid rounded"
                src="https://cdn.sanity.io/images/55lcecww/production/85805df65f10bdf6e1e286785ed939868c7dbebe-1326x524.png?w=600"
                alt="peakSuite Logo"
                layout="constrained"
                style={{ width: "65%" }}
                loading="lazy"
                fetchpriority="low"
              />
            </div>
            <h3>peakSUITE</h3>
            <p className="my-4" style={{ minHeight: "0rem" }}>
              Smash bottlenecks and rapidly scale your business beyond what you
              thought possible with a range of out-of-the-box solutions.
            </p>
            <br />
            <Link className="btn btn-primary btn-arrow" to="/peaksuite/">
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UnlockEnterprise
